<script setup lang="ts">
import type {FlightSearchForm} from "~/types/flight/search/FlightSearchForm";
import type {FlightSearchDate} from "~/types/flight/search/FlightSearchDate";
import {getMonthTextDayAndMonth} from "~/utils/calendar/persian-utils";

const props = defineProps<{
    state: FlightSearchForm
    showDatePiker: boolean,
    hasRoute?: boolean
    noPath?: boolean
}>()

const emit = defineEmits(['update:showDatePiker', 'update:date'])

const dateInput = computed(() =>
    getMonthTextDayAndMonth(props.state.date.start_date.persian, props.state.date.end_date?.persian)
)

const handleDate = (date: FlightSearchDate, close: boolean) => {
    if (close) emit('update:showDatePiker', false)
    emit('update:date', date)
}

</script>

<template>
    <div
        class="w-full flex gap-4 relative"
        :class="!noPath ? 'col-span-2' : 'col-span-3 col-start-3'"
    >
        <u-form-field
            name="start_date"
            :ui="{root: hasRoute ? 'w-full bg-transparent border border-white rounded-lg' : 'w-full bg-transparent border border-primary-900 rounded-lg', error: 'absolute text-xs'}"
        >
            <u-input
                v-model="dateInput"
                type="text"
                @focusin="emit('update:showDatePiker', true)"
                :placeholder="state.tripType.name === 'trip' ? 'تاریخ' : 'تاریخ ها'"
                color="gray"
                variant="none"
                :leading-icon="calendarIcon()"
                :ui="{base: `${hasRoute ? 'text-white' : ''}` , root: `${hasRoute ? 'text-white' : ''} w-full py-1 text-center  border-0 outline-none ring-0 focus:border-0 focus:outline-none focus:ring-0 bg-transparent`}"
                autocomplete="false"
                readonly
            />
            <div v-if="showDatePiker" class="absolute left-1/2 -translate-x-1/2 mt-1 w-[700px] z-20">
                <lazy-picker-custom-date-picker-component
                    @handle-date="handleDate"
                    :dates="state.date"
                    :selection-type="state.tripType.name === 'roundTrip' ? 'range' : 'single'"
                />
            </div>

            <template #error="{ error }">
                {{ error }}
            </template>
        </u-form-field>
    </div>
</template>
